import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { NotFoundGuard } from './guards/not-found/not-found.guard';
import { LoginGuard } from './guards/login/login.guard';
import { AuthGuard } from './guards/auth/auth.guard';
import { RedirectComponent } from './components/redirect/redirect.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'fale-conosco',
    loadChildren: () =>
      import('./pages/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'categorias',
    data: { flag: 'category' },
    loadChildren: () =>
      import('./pages/category/category.module').then((m) => m.CategoryModule),
  },
  {
    path: 'marcas',
    data: { flag: 'brand' },
    loadChildren: () =>
      import('./pages/category/category.module').then((m) => m.CategoryModule),
  },
  {
    path: 'necessidades',
    data: { flag: 'needs' },
    loadChildren: () =>
      import('./pages/category/category.module').then((m) => m.CategoryModule),
  },
  {
    path: 'produtos',
    data: { flag: 'category' },
    loadChildren: () =>
      import('./pages/products/products.module').then((m) => m.ProductsModule),
  },
  {
    path: 'produtos/tentantes',
    component: RedirectComponent,
    data: {
      url: 'https://myralis.com.br/tentantes',
    },
  },
  {
    path: 'produtos/:slug',
    loadChildren: () =>
      import('./pages/product/product.module').then((m) => m.ProductModule),
  },
  {
    path: 'onde-comprar',
    loadChildren: () =>
      import('./pages/stores/stores.module').then((m) => m.StoresModule),
  },
  {
    path: 'noticias',
    loadChildren: () =>
      import('./pages/news/news.module').then((m) => m.NewsModule),
  },
  {
    path: 'noticias/:slug',
    loadChildren: () =>
      import('./pages/news-single/news-single.module').then(
        (m) => m.NewsSingleModule
      ),
  },
  {
    path: 'artigos',
    loadChildren: () =>
      import('./pages/articles/articles.module').then((m) => m.ArticlesModule),
  },
  {
    path: 'artigos/:slug',
    loadChildren: () =>
      import('./pages/articles-single/articles-single.module').then(
        (m) => m.ArticlesSingleModule
      ),
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'resultados',
    loadChildren: () =>
      import('./pages/results/results.module').then((m) => m.ResultsModule),
  },
  {
    path: 'cadastro',
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./pages/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'recuperar-senha',
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./pages/password/lost/lost.module').then((m) => m.LostModule),
  },
  {
    path: 'redefinir-senha/:token',
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./pages/password/reset/reset.module').then((m) => m.ResetModule),
  },
  {
    path: 'minha-conta',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('./pages/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'sitemap',
    loadChildren: () =>
      import('./pages/sitemap/sitemap.module').then((m) => m.SitemapModule),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./pages/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'conteudo/:path',
    loadChildren: () =>
      import('./pages/content/content.module').then((m) => m.ContentModule),
  },
  {
    path: 'lp/:slug',
    loadChildren: () =>
      import('./pages/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'tentantes',
    loadChildren: () =>
      import('./pages/landing/tentantes/tentantes.module').then((m) => m.TentantesModule),
  },
  {
    path: '404',
    loadChildren: () =>
      import('./pages/no-results/no-results.module').then(
        (m) => m.NoResultsModule
      ),
  },
  {
    path: 'calculadoras/vitamina-d',
    loadChildren: () =>
      import('./pages/calculator/calculator.module').then(
        (m) => m.CalculatorModule
      ),
  },
  {
    path: 'calculadora-calcio',
    loadChildren: () =>
      import('./pages/calculator-calcium/calculator-calcium.module').then(
        (m) => m.CalculatorCalciumModule
      ),
  },
  {
    path: 'vaflexmsm',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/VA-FLEX-MSM-REFS-E-BULA.pdf?v=3',
    },
  },
  {
    path: 'mobiflex',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/204357-00-FO-MOBIFLEX-ENV-128X230.pdf',
    },
  },
  {
    path: 'mecobedprev-banner',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/Mini-bula-e-refereencias_MecoBe-e-Dprev-2.pdf',
    },
  },
  {
    path: 'tensart-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/204448-00-FO-TENSART-SOLUCAO-100MG-180x310.pdf',
    },
  },
  {
    path: 'mecobe-lamina',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/mecobeqr-codelamina-recall.pdf',
    },
  },
  {
    path: 'dprevative-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/2023_08%20FO%20DIGITAL%20DPREV%20ATIVE%2010MCG%20COM%20REV%20(180X230).pdf',
    },
  },
  {
    path: 'peg4000-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/204060-00-BU-PEG-LAX-4000-SOL-ORAL-180x310.pdf',
    },
  },
  {
    path: 'fertisop-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/2023_08-FO-DIGITAL-FERTISOP-128X230MM.pdf',
    },
  },
  {
    path: 'dprevcalcio',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/2024_03%20FO%20DIGITAL%20DPREV%20CALCIO%20COM%20REV%20(180X230).pdf',
    },
  },
  {
    path: 'dprevtododia-400uigotas-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/202345_folheto_Dprev-Todo-Dia_gotas_128x230mm_FO-00.pdf',
    },
  },
  {
    path: 'dprevtododia-600uigotas-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/202345_folheto_Dprev-Todo-Dia_gotas_128x230mm_FO-00.pdf',
    },
  },
  {
    path: 'dprevtododia-1000uigotas-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/202345_folheto_Dprev-Todo-Dia_gotas_128x230mm_FO-00.pdf',
    },
  },
  {
    path: 'dprevtododia-1000ui30caps-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/202327_folheto_Dprev-Todo-Dia_capsulas_128x230mm_FO-00.pdf',
    },
  },
  {
    path: 'dprevtododia-1000ui90caps-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/202327_folheto_Dprev-Todo-Dia_capsulas_128x230mm_FO-00.pdf',
    },
  },
  {
    path: 'dprevtododia-1000ui30cpr-bula',
    component: RedirectComponent,
    data: {
      //url: 'https://storage.myralis.com.br/production/drive/202324_folheto_Dprev-Todo-Dia_comprimidos_128x230mm_FO-00.pdf', OLD
      url: 'https://storage.myralis.com.br/production/drive/2023_10_FO_DIGITAL_DPREV_TD_DIA_COM_REV_128x230.pdf',
    },
  },
  {
    path: 'dprevtododia-2000ui30caps-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/202327_folheto_Dprev-Todo-Dia_capsulas_128x230mm_FO-00.pdf',
    },
  },
  {
    path: 'dprevtododia-2000ui90caps-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/202327_folheto_Dprev-Todo-Dia_capsulas_128x230mm_FO-00.pdf',
    },
  },
  {
    path: 'dprevtododia-2000ui30cpr-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/202324_folheto_Dprev-Todo-Dia_comprimidos_128x230mm_FO-00.pdf',
    },
  },
  {
    path: 'magnaliv-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/2023_11-FO-DIGITAL-MAGNALIV-COM-REV-128x230.pdf',
    },
  },
  {
    path: 'pealiv-600mg-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/2024_03%20FO%20DIGITAL%20PEALIV%20COM%20(128x230).pdf',
    },
  },
  {
    path: 'dprev-imunekids-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/2023_11-FO-DIGITAL-DPREV-IMUNE-KIDS-180X230.pdf',
    },
  },
  {
    path: 'puravit-multi-novo-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/2024_04_FO_DIGITAL_PURAVIT_MULTI_SOL_ORAL_180X230_03.pdf',
    },
  },
  {
    path: 'minibula-dor-e-mental',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/Minibula-Dor-e-Mental.pdf',
    },
  },
  {
    path: 'minibula-dor-e-mental-completa',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/Minibula-Dor-e-Mental-completa.pdf',
    },
  },
  {
    path: 'familia-peg-mini-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/MinibulaPEG.pdf'
    }
  },
  {
    path: 'citacoes-colonoscopia-peg',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/Citacoes_Colonoscopia_com_PEG.pdf'
    }
  },
  {
    path: 'ref-bula-memento',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/quartacapa_memento.pdf'
    }
  },
  {
    path: 'nactil-600mg',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/2023_09_FO_DIGITAL_NACTIL_600MG_COM_REV_128X230.pdf'
    }
  },
  {
    path: 'myraferc-c-80mg',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/2024_02_FO_DIGITAL_MYRAFER_C_COM_REV_180X230.pdf'
    }
  },
  {
    path: 'unizinco-solucao-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/204893_01_BU_UNIZINCO_4MG_SOL_ORAL_180x310_1.pdf'
    }
  },
  {
    path: 'myrafer-comprimidos',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/205027_01_BU_MYRAFER_100MG_COM_REV_180x310.pdf'
    }
  },
  {
    path: 'betrat-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/205111_01_BU_BETRAT_COM_REV_180X310.pdf'
    }
  },
  {
    path: 'mecobe-500mg-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/205144%2000%20BU%20MECOBE%20COM%20SUBL%20(180x310).pdf'
    }
  },
  {
    path: 'peglax-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/Mini%20bula%20PEG.pdf'
    }
  },
  {
    path: 'climatrix_bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/205187%2000%20BU%20CLIMATRIX%20100MG%20COM%20REV%20(180X310MM).pdf'
    }
  },
  {
    path: 'dprev-tododia-gotas-promo-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/TD%20GOTAS%201.pdf'
    }
  },
  {
    path: 'dprev-imune-kids-promo-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/IMUNE%201.pdf'
    }
  },
  {
    path: 'myrafer-gotas',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/205155%2000%20BU%20MYRAFER%20100MG%20SOL%20GOT%20(180X310).pdf'
    }
  },
  {
    path: 'peglax-17g-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/204892%2001%20BU%20PEGLAX%2017G%20ENV%20COM%20DOBRA%20(180x310).pdf'
    }
  },
  {
    path: 'dprev-4000ui-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%204.000%20UI.pdf'
    }
  },
  {
    path: 'dprev-5000ui-gotas-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%205.000%20UI%20GOTAS.pdf'
    }
  },
  {
    path: 'dprev-14000ui-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%2014.000%20UI%20CPR.pdf'
    }
  },
  {
    path: 'dprev-1000000ui-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%20100.000%20UI.pdf'
    }
  },
  {
    path: 'dprev-ative-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%20ATIVE.pdf'
    }
  },
  {
    path: 'dprev-calcio-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%20C%C3%81LCIO.pdf'
    }
  },
  {
    path: 'dprev-capsula-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%20MEDICAMENTO%20C%C3%81PSULAS.pdf'
    }
  },
  {
    path: 'dprev-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%20MEDICAMENTO%20COMPRIMIDOS.pdf'
    }
  },
  {
    path: 'dprev-tododia-caps-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%20TODO%20DIA%20C%C3%81PSULAS.pdf'
    }
  },
  {
    path: 'dprev-tododia-comp-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%20TODO%20DIA%20COMPRIMIDOS.pdf'
    }
  },
  {
    path: 'dprev-tododia-gotas-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%20TODO%20DIA%20GOTAS.pdf'
    }
  },
  {
    path: 'dprev-tripla-acao-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%20TRIPLA%20A%C3%87%C3%83O.pdf'
    }
  },
  {
    path: 'mecobe-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/205305%2000%20BU%20MECOBE%20COM%20SUBL%20(180x310).pdf'
    }
  },
  {
    path: 'peglax-4000-solucao-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/205014%2001%20BU%20PEG%20LAX%204000%20SOL%20ORAL%20(180x310).pdf'
    }
  },
  {
    path: 'fertisop-q10-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/2024_02%20FO%20DIGITAL%20FERTISOP%20Q10%20ENV%20(128X230)%20(1).pdf'
    }
  },
  {
    path: 'dprev-50000ui-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%2050.000%20UI%20CPR.pdf'
    }
  },
  {
    path: 'dprev-70000ui-bula',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%207.000%20UI%20CPR.pdf'
    }
  },
  {
    path: 'dprev-tododia-1000ui-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%20TODO%20DIA%20GOTAS%20v1.pdf'
    }
  },
  {
    path: 'dprev-tododia-600ui-gotas-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%20TODO%20DIA%20GOTAS%20v1.pdf'
    }
  },
  {
    path: 'dprev-tododia-400ui-gotas-folheto',
    component: RedirectComponent,
    data: {
      url: 'https://storage.myralis.com.br/production/drive/DPREV%20TODO%20DIA%20GOTAS%20v1.pdf'
    }
  },
  {
    path: '**',
    canActivate: [NotFoundGuard],
    loadChildren: () =>
      import('./pages/no-results/no-results.module').then(
        (m) => m.NoResultsModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
